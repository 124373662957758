import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Subject } from 'rxjs';

import { SplashScreenService } from './core/splash-screen/splash-screen.service';
import { environment } from '../environments/environment';
import { SocketIoClientService } from './core/utils/socket-io-client.service';
import { LoggedInUserService } from './core/loggedInUser/logged-in-user.service';
import { ProgressBarService } from './shared/progress-bar/progress-bar.service';
import { Theme, ThemeConfigurationService } from './theme-configuration.service';
import { UtilsService } from './core/utils/utils.service';
import { UserService } from './core/user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,
  OnDestroy {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _translateService: TranslateService,
    private _splashScreenService: SplashScreenService,
    private _usersService: UserService,
    private _router: Router,
    private _utilsService: UtilsService,
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private _loggedInUserService: LoggedInUserService,
    private _themeConfigService: ThemeConfigurationService,
    private _socketioClient: SocketIoClientService,
    public progressBarService: ProgressBarService,
  ) {
  }

  private _unsubscribeAll: Subject<void> = new Subject();
  private _arrayOfThemes: Theme[] = [];

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private transformProfileData(profile): void {
    profile.token = this._loggedInUserService.getAccountUser();
    profile.Agency = profile?.AgencyUser?.[0]?.Agency ?? null;
    profile.isAdmin = this._utilsService.isAdmin(profile.roles);

    this._loggedInUserService.updateUserProfile(profile);
    this._loggedInUserService.setAgencyUser(profile?.Agency);

    this._socketioClient._initSocket(this._loggedInUserService.getAccountUser());
    this.successHandle(profile);

    this.initializeTheme();
  }

  private successHandle(profile: any): void {
    if (!profile?.roles || (profile?.roles?.length === 0)) {
      this._usersService.logout()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe({
          next: (): void => {
            this._loggedInUserService.logout();
            this._router.navigate(['']).then();
          }
        });
    } else {
      if (profile?.roles?.[0]?.type === 'consultant') {
        this._router.navigate(['/backend-hotels/hotels-list']).then();
      } else if (profile?.roles?.[0]?.type === 'commercial') {
        this._router.navigate(['/backend-hotels/hotels-list']).then();
      } else {
        this._router.navigate(['/backend-hotels/backend-search']).then();
      }
    }
  }

  private initializeTheme(): void {
    if (this._loggedInUserService.getLoggedInUser()?.AgencyUser?.[0]?.Agency?.themeColor) {
      this._arrayOfThemes = this._themeConfigService.getArrayThemes();
      const idTheme = this._loggedInUserService.getLoggedInUser().AgencyUser[0].Agency.themeColor;
      this._themeConfigService.selectedTheme = this._arrayOfThemes?.[idTheme - 1] ?? this._arrayOfThemes?.[0];
      document.getElementById('BodyAppClass').className = this._themeConfigService.selectedTheme.className;
    } else {
      if ('agency' in localStorage) {
        let agencyImage = JSON.parse(localStorage.getItem('agency'));
        document.getElementById('BodyAppClass').className = this._themeConfigService.ListThemes[agencyImage.themeColor - 1].className;
        this.document.getElementById('fuse-splash-screen-image')
          .setAttribute('src', environment.apiImageUrl + agencyImage.image);
      } else {
        document.getElementById('BodyAppClass').className = this._themeConfigService.ListThemes[0].className;
        this.document.getElementById('fuse-splash-screen-image')
          .setAttribute('src', 'assets/images/havanatur/logo-white.png');
      }
    }
  }

  private initInternationalization(): void {
    this._translateService.addLangs(['es', 'ru', 'en']);
    let language: string = environment.defaultLanguage;

    if ('language' in localStorage) {
      language = localStorage.getItem('language');
      language = language.match(/en|es|ru|it/) ? language : 'en';
    } else {
      localStorage.setItem('language', language);
    }

    this._translateService.setDefaultLang(language);
    this._translateService.use(language);
    this._loggedInUserService.setLanguage(language);
    this._translateService.reloadLang(language);
  }

  private matIconRegister(): void {
    this._matIconRegistry.addSvgIcon(
      'air_conditioner',
      this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/air-conditioner.svg')
    );

    this._matIconRegistry.addSvgIcon(
      'towel_warmer',
      this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/towel-warmer.svg')
    );

    this._matIconRegistry.addSvgIcon(
      'bathtub',
      this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/bathtub.svg')
    );

    this._matIconRegistry.addSvgIcon(
      'electric_kettle',
      this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/electric-kettle.svg')
    );

    this._matIconRegistry.addSvgIcon(
      'balcony',
      this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/balcony.svg')
    );

    this._matIconRegistry.addSvgIcon(
      'umbrella',
      this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/umbrella.svg')
    );

    this._matIconRegistry.addSvgIcon(
      'bed_king_outline',
      this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/bed-king-outline.svg')
    );

    this._matIconRegistry.addSvgIcon(
      'bed_single_outline',
      this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/bed-single-outline.svg')
    );
  }

  ngOnInit(): void {
    this.initInternationalization();
    this.matIconRegister();
    this.initializeAccount();
  }

  private initializeAccount(): void {
    if (!this._loggedInUserService.getAccountUser()) {
      this._router.navigate(['authentication/login']).then();
    } else {
      this._loggedInUserService.getProfile()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe({
          next: (data): void => {
            this.transformProfileData(data.data);
          },
          error: (): void => {
            this._loggedInUserService.logout();
          }
        });
    }
  }
}
