import { Pipe, PipeTransform } from '@angular/core';
import { ICurrencyExchange } from 'src/app/core/interfaces/ICurrencyExchangeResponse';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'currencyExchange'
})
export class CurrencyExchangePipe implements PipeTransform {

  private defaultCurrency = environment.defaultCurrency;

  transform(value: unknown, market: string, exchangeData: ICurrencyExchange, currencyBase: string = undefined, currencyDest: string = undefined): number {

    if (!this.defaultCurrency || !market || !exchangeData) {
      return (value as number);
    }
    if (!currencyBase) {
      currencyBase = (market === 'internacional' || market === 'international') ? 'USD' : 'CUP';
    }
    if (!currencyDest) {
      currencyDest = this.defaultCurrency;
    }
    switch (market) {
      case 'nacional': {
        // if (currencyBase === 'CUP' && currencyDest === 'CUP' || currencyBase === currencyDest)
        return (value as number);
        // if (currencyBase === 'CUP')
        //   return (value as number) * (exchangeData.national.valor?.[currencyDest] ?? 1);
        // if (currencyDest === 'CUP' && currencyBase !== 'CUP')
        //   return (value as number) / (exchangeData.national.valor?.[currencyBase] ?? 1);
        // return (value as number) / exchangeData.national.valor?.[currencyBase] * exchangeData.national.valor?.[currencyDest]
      }
      case 'internacional': {
        if (currencyBase === 'USD' && currencyDest === 'USD' || currencyBase === currencyDest) {
          return (value as number);
        }
        if (currencyBase === 'USD') {
          return (value as number) * (exchangeData.international.valor?.[currencyDest] ?? 1);
        }
        if (currencyDest === 'USD' && currencyBase !== 'USD') {
          return (value as number) / (exchangeData.international.valor?.[currencyBase] ?? 1);
        }
        return (value as number) / exchangeData.international.valor?.[currencyBase] * exchangeData.international.valor?.[currencyDest];
      }
      default: {
        return (value as number);
      }
    }
  }
}
