import { DisplayCurrency } from '../app/core/interfaces/displayCurrency';
import { Environment } from '../app/core/interfaces/environments';
import packageInfo from '../../package.json';

export const environment: Environment = {
  production: true,
  defaultAgencyName: 'Viajes San Cristobal',
  defaultCurrency: 'USD',
  defaultDisplayCurrency: DisplayCurrency.code,
  ventasActivas: false,
  hasMultipleAgencies: false,
  hasReferredUsers: false,
  includeTransportMenu: false,
  canSetQuotesAccess: true,
  getInfoManyHolders: false,
  showOriginHotelsInBusStop: false,
  showYoomoneyPercentInput: false,
  canSellerEditPricesInCalendar: true,
  hasPreReserve: false,
  includeDownloadBreakdownFacture: false,
  hasRetiredUnnecessaryInputsInReserve: false,
  hasSellRestriction: true,
  timePreChecking: 72,
  hideColumnRegion: false,
  hideColumnprocessed: false,
  hideColumnOfferCode: false,
  showDBF: false,
  showCheckAndWireTransfer: true,
  hasBankTransferPayment: false,
  hasPMSLocator: false,
  version: packageInfo.version,
  babiesAgeLimit: 12,
  childrenAgeLimit: 18,
  limit: 5,
  roomsLimit: 8,
  marketType: ['nacional', 'internacional'],
  defaultMarket: {
    lat: 23.131268,
    lng: -82.3582717
  },
  gateways: {
    sber: false,
    cash: true,
    pos: true,
    enzona: false,
    enzonaMlc: false,
    transfermovil: true,
    bidaiondo: true,
    peoplegoto: false,
    adg: false,
    guajipay: false,
    posQr: false,
    linkeao: false,
    credit: false,
    yoomoney: false,
    'in-transit': false,
    check: false,
    bankTransfer: false,
    tropipay:false
  },
  slogan: 'Viajes San Cristobal',
  genericLogo: 'generic-image.png',
  apiSeg: 'https://apiseg.viajessancristobal.com/v1/',
  apiUrl: 'https://apihoteles.viajessancristobal.com/v1/',
  apiAuthenticationUrl: 'https://apihoteles.viajessancristobal.com/v1/',
  apiSearchHouseUrl: 'https://apicasas.viajessancristobal.com/v1/',
  apiSearchHotelUrl: 'https://apihoteles.viajessancristobal.com/v1/',
  apiImageUrl: 'https://cdnturismo.viajessancristobal.com/',
  clientAgencyMultiService: 'https://gds.viajessancristobal.com',
  urlOkRedirect: 'https://adminhoteles.viajessancristobal.com/backend-hotels/hotels-reservations/',
  urlKoRedirect: 'https://adminhoteles.viajessancristobal.com/backend-hotels/backend-search/',
  logo_voucher: 'assets/images/havanatur/logo-color.png',
  AgencyFrontId: 1,
  agencyName: 'Viajes San Cristobal',
  address: 'Viajes San Cristobal',
  phone: 'Viajes San Cristobal',
  fax: 'Viajes San Cristobal',
  email: 'sales@viajessancristobal.com',
  googleKey: 'AIzaSyAqiIgEulb61nXgVRmxRNEIcEru4IubI9g',
  defaultLanguage: 'en',
  measurementUnits: [
    {
      id: 'squareMeters',
      name: {
        es: 'Metros cuadrados',
        en: 'Square meters'
      }
    },
    {
      id: 'squareFeet',
      name: {
        es: 'Pies cuadrados',
        en: 'Square feet'
      }
    }
  ],
  smokingPolicyOptions: [
    {
      id: 'smokingProhibited',
      name: {
        es: 'Se prohibe fumar',
        en: 'Smoking prohibited'
      }
    },
    {
      id: 'smokingAllowed',
      name: {
        es: 'Se permite fumar',
        en: 'Smoking is allowed'
      }
    }
  ],
  domain: 'viajessancristobal.com',
  gdsServices: [
    {
      icon: 'timeline',
      id: 'dashboard',
      route: 'https://portalventas.viajessancristobal.com',
      name: 'Dashboard',
      isActive: true
    },
    {
      icon: 'apartment',
      id: 'hotel',
      route: 'https://adminhoteles.viajessancristobal.com',
      name: 'Hoteles',
      isActive: false
    },
    {
      icon: 'flight',
      id: 'flight',
      name: 'Charter',
      route: 'https://adminvuelos.viajessancristobal.com/',
      isActive: false
    },
    {
      icon: 'local_play',
      id: 'experiences',
      name: 'Experiencias',
      route: 'https://adminexperiencias.viajessancristobal.com/',
      isActive: true
    },
    {
      icon: 'local_taxi',
      id: 'car',
      route: 'https://adminautos.viajessancristobal.com/',
      name: 'Autos',
      isActive: false
    },
    {
      icon: 'house',
      id: 'house',
      name: 'Casas',
      route: 'https://admincasas.viajessancristobal.com/',
      isActive: false
    },
    {
      icon: 'airport_shuttle',
      id: 'transfer',
      name: 'Transfer',
      route: 'https://admintransfer.viajessancristobal.com/',
      isActive: false
    }
  ],
  strictSelection:true,
  hideShowMoreInSearch:true
};
