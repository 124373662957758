import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as io from 'socket.io-client';

import { LoggedInUserService } from 'src/app/core/loggedInUser/logged-in-user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketIoClientService implements OnDestroy {
  constructor(
    private loggedInUserService: LoggedInUserService
  ) {

    this.loggedInUser = this.loggedInUserService.getLoggedInUser();

    this.loggedInUserService?.loggedInUserUpdated$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (): void => {
          this.loggedInUser = this.loggedInUserService.getLoggedInUser();
        }
      });
  }

  private socket: SocketIOClient.Socket;
  private socketSearch: SocketIOClient.Socket;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  loggedInUser: any;
  public url: string = environment.apiUrl + 'connect';

  public sendMessage(eventname, message): void {
    this.socket.emit(eventname, message);
  }

  // HANDLER
  listen(eventname): Observable<any> {
    return Observable.create(observer => {
      this.socket?.on(eventname, (data) => {
        observer.next(data);
      });
    });
  }

  // HANDLER
  listenSearch(eventname): Observable<any> {
    return Observable.create(observer => {
      this.socketSearch?.on(eventname, (data) => {
        observer.next(data);
      });
    });
  }

  public initSearchSocket(UUID): void {
    this.socketSearch = io(this.url, {
      query: {
        Authorization: 'uuid ' + UUID
      }
    }).connect();

  }

  public _initSocket(token): void {
    this.socket = io(this.url, {
      query: {
        Authorization: token
      }
    }).connect();
  }

  public disconnectSearchSocket(): void {
    if (this.socketSearch) {
      this.socketSearch.disconnect();
    }
  }

  public disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this.disconnect();
    this.disconnectSearchSocket();
  }

}
