import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoggedInUserService } from '../loggedInUser/logged-in-user.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  constructor(
    private loggedInUserService: LoggedInUserService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = this.loggedInUserService.getLoggedInUser();
    const language = this.loggedInUserService.getLanguage();
    const token = this.loggedInUserService.getAccountUser();

    if (user?.token && !request.url.includes('login') && !request.url.includes('agency-by-mail')) {
      request = request.clone({
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': user.token,
            'language': language,
            'isFront': 'false'
          }
        )
      });

    } else if (token && !request.url.includes('login') && !request.url.includes('agency-by-mail')) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'language': language,
          'isFront': 'false'
        }
      });
    }

    return next.handle(request);
  }
}
