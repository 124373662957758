import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { IUser } from '../classes/user.class';
import { LoggedInUserService } from '../loggedInUser/logged-in-user.service';
import { IPagination } from '../classes/pagination.class';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private _httpClient: HttpClient,
    private _loggedInUserService: LoggedInUserService
  ) {
  }

  url = environment.apiUrl + 'user';
  urlId = environment.apiUrl + 'user/:userId';
  clientUrl = environment.apiUrl + 'user';
  urlLogout = environment.apiAuthenticationUrl + 'auth/logout';
  lastRoute = '';
  httpOptions = {};
  allUsers: IUser[] = [];

  readonly usersUpdated$: BehaviorSubject<IUser[]> = new BehaviorSubject<IUser[]>(null);
  readonly userUpdated$: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);
  readonly routeChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  emitUsersUpdated(value: IUser[]) {
    this.allUsers = value;
    this.usersUpdated$.next(value);
  }

  emitUserUpdated(value: IUser) {
    this.userUpdated$.next(value);
  }

  emitRouteChange() {
    this.routeChange$.next(true);
  }

  setLastRoute(route: string) {
    this.lastRoute = route;
  }

  getLastRoute(): string {
    return this.lastRoute;
  }

  addUser(user: IUser): Observable<IUser> {
    // TODO: Required for the InMemoryDb to work.
    return this._httpClient.post<IUser>(this.url, user);
  }

  editUser(data): Observable<unknown> {
    return this._httpClient
      .patch<any>(this.urlId.replace(':userId', data.id), data, this.httpOptions);
  }

  getAllUsers(query?: IPagination, params?: any): Observable<unknown> {
    let httpParams: HttpParams = new HttpParams();
    if (query) {
      httpParams = httpParams.append('limit', query.limit.toString());
      httpParams = httpParams.append('offset', query.offset.toString());

      if (query.filter && query.filter.properties && query.filter.filterText) {
        query.filter.properties.forEach(item => {
          httpParams = httpParams.append(item, '%' + query.filter.filterText + '%');
        });
      }

      if (query.order) {
        httpParams = httpParams.append('order', query.order);
      }
    } else {
      httpParams = httpParams.set('limit', '10');
      httpParams = httpParams.set('offset', '0');
    }
    if (params) {
      if (params?.roles === 'admin') {
        if (params?.selectedRoles?.length) {
          if (params.selectedRoles.length > 1) {
            params.selectedRoles.forEach((rol): void => {
              httpParams = httpParams.append('filter[$and][Rol][type][$in]', rol);
            });
          } else {
            httpParams = httpParams.append('filter[$and][Rol][type][$like]', params.selectedRoles[0]);
          }
        } else {
          httpParams = httpParams.append('filter[$and][Rol][type][$in]', 'admin');
          httpParams = httpParams.append('filter[$and][Rol][type][$in]', 'seller');
          httpParams = httpParams.append('filter[$and][Rol][type][$in]', 'commercial');
        }
      } else {
        httpParams = httpParams.append('filter[$and][Rol][type]', params.roles);
      }

      if (params?.AgencyId) {
        httpParams = httpParams.append('filter[$and][AgencyUser][AgencyId]', params.AgencyId);
      }
    }
    return this._httpClient
      .get<any>(this.url, { params: httpParams });
  }

  getAllUsersChildren(query?: IPagination, params?: any): Observable<unknown> {
    let httpParams = new HttpParams();
    if (query) {
      httpParams = httpParams.append('limit', query.limit.toString());
      httpParams = httpParams.append('offset', query.offset.toString());

      if (query.filter && query.filter.properties && query.filter.filterText) {
        query.filter.properties.forEach(item => {
          httpParams = httpParams.append(item, '%' + query.filter.filterText + '%');
        });
      }

      if (query.order) {
        httpParams = httpParams.append('order', query.order);
      }
    } else {
      httpParams = httpParams.set('limit', '0');
      httpParams = httpParams.set('offset', '0');
    }
    if (params) {
      if (params.roles) {
        httpParams = httpParams.append('filter[$and][Rol][type]', params.roles);
      }
      if (params.AgencyId) {
        httpParams = httpParams.append('filter[$and][AgencyUser][AgencyId]', params.AgencyId);
      }
    }
    return this._httpClient
      .get<any>(this.url + '/children', { params: httpParams });
  }

  getUser(data): Observable<unknown> {
    if (typeof (data) === 'number') {
      return this._httpClient
        .get<IUser>(this.urlId.replace(':userId', data.toFixed()), this.httpOptions);
    } else {
      return this._httpClient
        .get<IUser>(this.urlId.replace(':userId', data.id), this.httpOptions);
    }

  }

  removeUser(data): Observable<unknown> {
    return this._httpClient
      .delete<IUser>(this.urlId.replace(':userId', data.id), this.httpOptions);
  }

  ////////////////// CLIENT PARTS ////////////////////

  getAllClients(query?: IPagination, params?: any): Observable<unknown> {
    let httpParams: HttpParams = new HttpParams();
    if (query) {
      httpParams = httpParams.append('limit', query.limit.toString());
      httpParams = httpParams.append('offset', query.offset.toString());

      if (query.filter && query.filter.properties) {
        query.filter.properties.forEach(item => {
          httpParams = httpParams.append(item, '%' + query.filter.filterText + '%');
        });
      }

      if (query.order) {
        httpParams = httpParams.append('order', query.order);
      }
    } else {
      httpParams = httpParams.set('limit', '0');
      httpParams = httpParams.set('offset', '0');
    }
    if (params) {
      if (params.roles) {
        httpParams = httpParams.append('filter[$and][Rol][type]', params.roles);
      }
      if (params.AgencyId) {
        httpParams = httpParams.append('filter[$and][AgencyUser][AgencyId]', params.AgencyId);
      }
    }
    return this._httpClient
      .get<any>(this.clientUrl, { params: httpParams });
  }

  getAllSeller(query?: IPagination, params?: any): Observable<unknown> {
    let httpParams: HttpParams = new HttpParams();
    if (query) {
      let httpParams = new HttpParams();
      httpParams = httpParams.append('limit', query.limit.toString());
      httpParams = httpParams.append('offset', query.offset.toString());

      if (query.filter && query.filter.properties) {
        query.filter.properties.forEach(item => {
          httpParams = httpParams.append(item, '%' + query.filter.filterText + '%');
        });
      }

      if (query.order) {
        httpParams = httpParams.append('order', query.order);
      }
    } else {
      httpParams = httpParams.set('limit', '0');
      httpParams = httpParams.set('offset', '0');
    }

    httpParams = httpParams.append('filter[$and][Rol][type]', 'seller');

    return this._httpClient
      .get<any>(this.url, { params: httpParams });
  }

  addClient(Client: any): Observable<unknown> {
    return this._httpClient
      .post<any>(
        this.clientUrl,
        Client);
  }

  editClient(data: any): Observable<unknown> {
    return this._httpClient
      .patch<any>(this.clientUrl + '/' + data.id + '', data, this.httpOptions);
  }

  removeClient(data): Observable<unknown> {
    return this._httpClient
      .delete<any>(this.clientUrl + '/' + data.id + '', this.httpOptions);
  }

  logout(): Observable<unknown> {
    if (this._loggedInUserService.getLoggedInUser()?.token) {
      return this._httpClient.post<any>(
        this.urlLogout, {
          Authorization: this._loggedInUserService.getLoggedInUser().token
        },
        this.httpOptions
      );
    }
  }

  getAllAdminAndClient(): Observable<unknown> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('filter[$and][Rol][type][$in]', 'admin');
    httpParams = httpParams.append('filter[$and][Rol][type][$in]', 'seller');
    return this._httpClient
      .get<any>(this.url, { params: httpParams });
  }

  getAutocomplete(text): Observable<unknown> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = new HttpParams()
      .set('limit', '20')
      .set('offset', '0');

    if (text) {
      httpParams = httpParams.append('filter[$or][name][$like]', '%' + text + '%');
      httpParams = httpParams.append('filter[$or][email][$like]', '%' + text + '%');
      httpParams = httpParams.append('filter[$or][lastname][$like]', '%' + text + '%');
    }

    this.httpOptions = {
      params: httpParams
    };

    return this._httpClient.get<any>(this.url, this.httpOptions);
  }
}
