import * as LoggedInUserActions from '../actions/logged-in-user.action';
import { IUser } from '../../core/classes/user.class';


export interface LoggedInUserState {
  loggedInUser: IUser;
}

const initialState: LoggedInUserState = {
  loggedInUser: null
};

export function reducer(state = initialState,
                        action: LoggedInUserActions.LoggedInUserActions): LoggedInUserState {
  switch (action.type) {
    case (LoggedInUserActions.SET_LOGGED_IN_USER):
      return {
        ...state,
        loggedInUser: action.payload
      };
    case (LoggedInUserActions.CLEAR_LOGGED_IN_USER):
      return {
        ...state,
        loggedInUser: null
      };
    default:
      return state;
  }
}

export const getLoggedInUser = (state: LoggedInUserState) => state.loggedInUser;
