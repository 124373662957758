import { ShowToastrService } from './../show-toastr/show-toastr.service';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(
    private httpClient: HttpClient,
    private showToastr: ShowToastrService,
    private translateService: TranslateService,
  ) {
  }

  urlCountry = environment.apiUrl + 'country';

  private generator(): string {
    const isString = `${this.S4()}${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}${this.S4()}${this.S4()}`;

    return isString;
  }

  private S4(): string {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  static getOnlyDate(date: string): string {
    if (date) {
      const dateStrings = date.split('T');
      if (dateStrings.length === 2) {
        return dateStrings[0];
      }
    }

    return '-';
  }

  // static getDateAndTime(date, hour?) {
  //   const elem = date.split('-');
  //   const d = new Date();
  //   d.setFullYear(elem[0], elem[1] - 1);
  //   d.setDate(elem[2]);
  //
  //   if (hour) {
  //     const elemHour = hour.split(/[/ :]/);
  //     d.setHours(elemHour[0], elemHour[1], 0);
  //   }
  //
  //   return d;
  // }

  static resolvePropertyByPath(obj: any, path: string[]) {
    let result = path.reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, obj || self);

    return result;
  }

  static getStringDate(date: Date): string {
    var year = date.getFullYear();
    var mes = date.getMonth() + 1;
    var dia = date.getDate();
    var fecha = year + '-' + (mes < 10 ? '0' + mes : mes) + '-' + (dia < 10 ? '0' + dia : dia);
    return fecha;
  }

  static getDateAndTime(date, hour?) {
    let d = new Date();
    if (typeof date === 'string') {
      const elem = date.split('-');
      let month = parseInt(elem[1]) - 1;
      d.setMonth(month);
      d.setDate(parseInt(elem[2]));
      d.setFullYear(parseInt(elem[0]));
    } else {
      d = date;
    }

    if (hour) {
      const elemHour = hour.split(/[/ :]/);
      d.setHours(elemHour[0], elemHour[1], 0);
    }
    return d;
  }

  public static showValue(field, lang): string {
    if (typeof field === 'string') {
      const data = JSON.parse(field);
      return data[lang] ? data[lang] : data['es'] ? data['es'] : data['en'] ? data['en'] : data['ru'] ? data['ru'] : '';
    } else {
      return field[lang]
        ? field[lang]
        : field['es']
          ? field['es']
          : field['en']
            ? field['en']
            : field['ru']
              ? field['ru']
              : '';
    }
  }

  public isObjectEquals(x, y): boolean {
    if (x === y) {
      return true;
    }

    if (!(x instanceof Object) || !(y instanceof Object)) {
      return false;
    }

    if (x.constructor !== y.constructor) {
      return false;
    }

    for (const p in x) {
      if (!x.hasOwnProperty(p)) {
        continue;
      }

      if (!y.hasOwnProperty(p)) {
        return false;
      }

      if (x[p] === y[p]) {
        continue;
      }

      if (typeof x[p] !== 'object') {
        return false;
      }

      if (!this.isObjectEquals(x[p], y[p])) {
        return false;
      }
    }
    for (const p in y) {
      if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
        return false;
      }
    }
    return true;
  }

  //retorna true  si el precio del hotel a es mayor que el b
  public orderHotel(a, b, searchMode) {
    if (searchMode === 'category') {
      if (parseFloat(a.category) < parseFloat(b.category)) {
        return true;
      } else {
        return false;
      }
    } else if (searchMode === 'name') {
      if (a.name > b.name) {
        return true;
      } else {
        return false;
      }
    } else {
      let aPrice = parseFloat(a.startingPrice);
      if (a.offer && a.offer !== 0) {
        aPrice = a.offer;
      }
      let bPrice = parseFloat(b.startingPrice);
      if (b.offer && b.offer !== 0) {
        bPrice = b.offer;
      }
      if (aPrice > bPrice) {
        return true;
      } else {
        return false;
      }
    }
  }

  public getValueString(field, lang): string {
    if (typeof field === 'string') {
      const data = JSON.parse(field);
      return data?.[lang] ? data[lang] : data?.['es'] ? data['es'] : data?.['en'] ? data['en'] : data?.['ru'] ? data['ru'] : '';
    } else {
      return field?.[lang]
        ? field[lang]
        : field?.['es']
          ? field['es']
          : field?.['en']
            ? field['en']
            : field?.['ru']
              ? field['ru']
              : '';
    }
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  subString(max, str) {
    if (str && str.length > max) {
      return str.substring(0, max) + '...';
    } else {
      return str;
    }
  }

  // formatPhone(cellphone): String {
  //   if (cellphone && this.validatePhone(cellphone)) {
  //     return formatNumber(parseNumber(cellphone, 'CU'), 'National');
  //   } else if (cellphone && isValidNumber(cellphone, 'US')) {
  //     return formatNumber(parseNumber(cellphone, 'US'), 'National');
  //   } else {
  //     return cellphone;
  //   }
  // }

  jsUcfirst(str) {
    let ayuda =
      'de la que el en y a los del se las por un para con no una su al lo como más pero sus le ya o este sí porque esta entre cuando muy sin sobre también me hasta hay donde quien desde todo nos durante todos uno les ni contra otros ese eso ante ellos e esto mí antes algunos qué unos yo otro otras otra él tanto esa estos mucho quienes nada muchos cual poco ella estar estas algunas algo nosotros mi mis tú te ti tu tus ellas nosotras vosostros vosostras os mío mía míos mías tuyo tuya tuyos tuyas suyo suya suyos suyas nuestro nuestra nuestros nuestras vuestro vuestra vuestros vuestras esos esas estoy estás está estamos estáis están esté estés estemos estéis estén estaré';

    return str
      .toLowerCase()
      .split(' ')
      .map(function(word) {
        if (ayuda.indexOf(word.toLowerCase()) !== -1) {
          return word;
        } else {
          return word.replace(word[0], word[0].toUpperCase());
        }
      })
      .join(' ');
  }

  validatePhone(cellphone) {
    if (cellphone.length !== 8 || cellphone[0] !== '5' || isNaN(Number(cellphone))) {
      return false;
    } else {
      return true;
    }
  }

  isAdmin(roles: any[]) {
    if (roles?.length) {
      let result = false;
      roles.forEach((item) => {
        if (item.type === 'admin' || item.type === 'Admin') {
          result = true;
        }
      });
      return result;
    } else {
      return false;
    }
  }

  toLowerCase(data) {
    return data.toLowerCase();
  }

  public keyPressAlphaSpace(event): boolean {
    const inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z 0-9,.]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  public keyPressLettersAndSpace(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;

    // Permitir letras mayúsculas, minúsculas y espacio
    if ((charCode >= 65 && charCode <= 90) || // Letras mayúsculas
      (charCode >= 97 && charCode <= 122) || // Letras minúsculas
      charCode === 32) { // Espacio
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  public keyPressDecimalNumbers(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 46 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  public keyPressNumbers(key: any): boolean {
    if (!key) {
      return null;
    }
    return key.charCode === 8 || key.charCode === 0 ? null : key.charCode >= 48 && key.charCode <= 57;
  }

  errorHandle(error, nomenclator?, action?) {
    let alternative = nomenclator
      ? action
        ? this.translateService.instant('Error ') + action + ' ' + nomenclator
        : this.translateService.instant('Error ') + action
      : this.translateService.instant(
        `Server response failed, check your connection to the network, or contact the administrators`,
      );
    let msg = alternative;
    if (error.errors && error.errors.length) {
      msg = error.errors.map((item) => item.title + ' ' || item.message + ' ');
    } else if (error.error.errors) {
      msg = error.error.errors.map((item) => item.title + ' ' || item.message + ' ');
    } else if (error.error && error.error.length) {
      msg = error.error.map((item) => item.title + ' ' || item.message + ' ');
    } else {
      msg = error.error.message;
    }
    this.showToastr.showError(msg, 'Error', 9000);
  }

  errorHandle2(error, nomenclator?, action?) {
    let alternative = nomenclator
      ? action
        ? this.translateService.instant('Error ') + action + ' ' + nomenclator
        : this.translateService.instant('Error ') + action
      : this.translateService.instant(
        `Server response failed, check your connection to the network, or contact the administrators`,
      );
    let msg =
      error.error && error.error.errors && error.error.errors.length
        ? error.error.errors.map((item) => item.title)
        : alternative;
  }

  getCountries(): Observable<any> {
    return this.httpClient.get<any>(this.urlCountry).pipe(map((item) => item.data));
  }

  public getReservationModalities(): Observable<any> {
    return this.httpClient.get<any>(environment.apiUrl + 'reservation-type');
  }

  generateUuid(): string {
    let tempId = '';

    tempId = this.generator();

    return tempId;
  }
}
