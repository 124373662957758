import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICurrencyExchange, ICurrencyExchangeResponse } from '../interfaces/ICurrencyExchangeResponse';

@Injectable({
  providedIn: 'root'
})
export class CurrencyExchangeService {

  private $currencyExchange: BehaviorSubject<ICurrencyExchange> = new BehaviorSubject(null);

  url = environment.apiUrl + 'config';

  constructor(private httpClient: HttpClient) {

  }

  public getCurrencyExchange(): Observable<any> {
    return this.$currencyExchange.asObservable().pipe(
      filter(response => !!response)
    );
  }

  public getCurrencyExchangeRatefromAPI(): Observable<void> {

    let httpParamsInt: HttpParams = new HttpParams();
    let httpParamsNat: HttpParams = new HttpParams();
    httpParamsInt = httpParamsInt.set('filter[$and][llave]', 'currencies');
    httpParamsNat = httpParamsNat.set('filter[$and][llave]', 'currencies-national');


    return forkJoin({
      international: this.httpClient.get<ICurrencyExchangeResponse>(this.url, { params: httpParamsInt }),
      national: this.httpClient.get<ICurrencyExchangeResponse>(this.url, { params: httpParamsNat })
    }).pipe(
      map(response => {
        return {
          ...response,
          international: Array.isArray(response.international?.data) ? response.international.data[0] : response.international.data,
          national: Array.isArray(response.national?.data) ? response.national.data[0] : response.national.data
        };
      }),
      map(mapResponse => {
        return {
          ...mapResponse,
          international: {
            ...mapResponse.international,
            valor: JSON.parse(mapResponse.international.valor)
          },
          national: {
            ...mapResponse.national,
            valor: JSON.parse(mapResponse.national.valor)
          }
        };
      }),
      tap((response) => {
        this.$currencyExchange.next(response);
      }),
      switchMap(_ => {
        return of();
      })
    );

  }
}
