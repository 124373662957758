<div mat-dialog-content>
  <div class='p-3' fxLayout='column' fxLayoutAlign='center center'>
    <span class='mb-2'>
      {{'Nueva versión del sistema detectada. Se cargará nuevamente la página.' | translate}}
    </span>

    <button (click)='onClose()' color='primary' mat-button>
      {{ "Aceptar" | translate | uppercase}}
    </button>
  </div>
</div>
