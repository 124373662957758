import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpLoaderFactory } from './core/translate-factory/translate-loader';
import { environment } from '../environments/environment';
import { CustomSerializer, reducers } from './store';
import { TokenInterceptorService } from './core/interceptors/token-interceptor.service';
import { ErrorInterceptorService } from './core/interceptors/error-interceptor.service';
import { ProgressBarModule } from './shared/progress-bar/progress-bar.module';
import { CurrencyExchangePipe } from './shared/pipes/currency-exchange/currency-exchange.pipe';
import { UpdateControllerModule } from './core/updates-controller/update-controller.module';
import { ShareExternalHotelInfoServices } from './backend-hotels/profile-external/core/services/shareExtarnalHotelInfo';
import { CurrencyExchangeService } from './core/currency-exchange/currency-exchange.service';
import { ShareReservationDataService } from './core/change-reservation-data/share-reservation-data.services';

registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      timeOut: 4000,
      progressBar: true,
      positionClass: 'toast-bottom-right'
    }),
    HttpClientModule,
    ProgressBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    UpdateControllerModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es'
    },
    {
      provide: RouterStateSerializer,
      useClass: CustomSerializer
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    CurrencyExchangePipe,
    CurrencyExchangeService,
    ShareExternalHotelInfoServices,
    ShareReservationDataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
