import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-reload-app',
  templateUrl: './dialog-reload-app.component.html',
  styleUrls: ['./dialog-reload-app.component.scss']
})
export class DialogReloadAppComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogReloadAppComponent>,
    public dialog: MatDialog
  ) {
    this.dialogRef.disableClose = true;
  }

  public onClose(): void {
    this.dialogRef.close(true);
  }
}
