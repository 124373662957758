import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IPagination } from 'src/app/core/classes/pagination.class';



@Injectable({
  providedIn: 'root'
})
export class ShareReservationDataService {

  private urlfirst = environment.apiUrl + 'reservation/modifications/first';

  constructor( private http: HttpClient) {
  }

  private reservationData:BehaviorSubject<any> = new BehaviorSubject<any>(null);

  getReservationData():Observable<any>{
    return this.reservationData.asObservable();
  }

  setReservationData(reservation:any):void{
    this.reservationData.next(reservation);
  }

  setReservationSoftData(data:any):Observable<any>{
    return this.http.post(this.urlfirst, data);
  }


}

