import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptService {
  secretKey = 'YourSecretKeyForEncryption&Descryption';

  encrypt(value: string): string {
    try {
      const encrypted = CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
      return encrypted;
    } catch (error) {
      return null;
    }
  }

  decrypt(textToDecrypt: string) {
    try {
      const decrypted = CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
      return decrypted;
    } catch (error) {
      return null;
    }
  }
}
