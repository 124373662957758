import { TranslateService } from '@ngx-translate/core';
import { Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { UtilsService } from '../utils/utils.service';
import { ShowToastrService } from '../show-toastr/show-toastr.service';
import { LoggedInUserService } from '../loggedInUser/logged-in-user.service';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(
    private loggedInUserService: LoggedInUserService,
    private showToastr: ShowToastrService,
    private utilsService: UtilsService,
    private zone: NgZone,
    private router: Router,
    private translate: TranslateService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Error del lado del cliente: ${error.error.message}`;
          this.showToastr.showError(errorMessage, 'Error');
        } else {
          errorMessage = error.error;
          this.processingBackendError(error);
        }
        return throwError(errorMessage);
      })
    );
  }

  processingBackendError(err: HttpErrorResponse): void {
    if (err.status === 401) {
      this.utilsService.errorHandle(err);
      localStorage.removeItem('user');
      this.loggedInUserService.setLoggedInUser(null);
      this.loggedInUserService.loggedInUserUpdated$.next(null);
      this.zone.run((): void => {
        this.router.navigate(['']).then();
      });
    } else if (err.status === 403) {
      if (this.router.url.includes('backend-hotels')) {
        this.zone.run((): void => {
          this.router.navigate(['/backend-hotels/error/403']).then();
        });
      } else {
        this.zone.run((): void => {
          this.router.navigate(['/error/403']).then();
        });
      }
    } else if (err.status === 404) {
      if (this.router.url.includes('backend-hotels')) {
        this.zone.run((): void => {
          this.router.navigate(['/backend-hotels/error/404']).then();
        });
      } else {
        this.zone.run((): void => {
          this.router.navigate(['/error/404']).then();
        });
      }
    } else if (err.status === 400 || err.status == 500) {
      this.utilsService.errorHandle(err);
    } else if (err.status === 0) {
      if (this.router.url.includes('backend-hotels')) {
        this.zone.run((): void => {
          this.router.navigate(['/backend-hotels/error/lost-connection']).then();
        });
      } else {
        this.zone.run((): void => {
          this.router.navigate(['/error/lost-connection']).then();
        });
      }
      this.showToastr.showError(
        this.translate.instant(
          `Server response failed, check your connection to the network, or contact the administrators`
        )
      );
    }
  }
}
