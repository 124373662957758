import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { AuthenticationGuard } from './authentication.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../app/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'authentication',
    loadChildren: () => import('../app/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'backend-hotels',
    loadChildren: () => import('../app/backend-hotels/backend-hotels.module').then(m => m.BackendHotelsModule)
  },
  {
    path: 'error',
    loadChildren: () => import('../app/errors/errors.module').then(m => m.ErrorsModule)
  },
  {
    path: 'admin-countries',
    loadChildren: () => import('./backend-hotels/admin-countries/admin-countries.module').then(m => m.AdminCountriesModule)
  },
  {
    path: 'admin-provinces',
    loadChildren: () => import('./backend-hotels/admin-provinces/admin-provinces.module').then(m => m.AdminProvincesModule)
  },
  {
    path: 'admin-municipality',
    loadChildren: () => import('./backend-hotels/admin-municipality/admin-municipality.module').then(m => m.AdminMunicipalityModule)
  },
  {
    path: '**',
    redirectTo: 'authentication',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
