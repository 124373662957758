import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import Cookies from 'js-cookie';

import { concat, interval, Subject } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogReloadAppComponent } from './dialog-reload-app/dialog-reload-app.component';
import { first } from 'rxjs/operators';
// import { UserService } from '@services/general/user.service';
// import { LoggedInUserService } from '@services/general/logged-in-user.service';
import { Router } from '@angular/router';

@Injectable()
export class ServiceWorkerUpdateService {
  public $updatedVersion = new Subject<any>();

  constructor(
    public appRef: ApplicationRef,
    public updates: SwUpdate,
    // private usersService: UserService,
    // private loggedInUserService: LoggedInUserService,
    private router: Router,
    public dialog: MatDialog
  ) {
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const everyMinute$ = interval(60 * 1000);
    const everyMinuteOnceAppIsStable$ = concat(appIsStable$, everyMinute$);

    if (updates.isEnabled) {
      everyMinuteOnceAppIsStable$.subscribe(async () => {
        try {
          const updateFound = await updates.checkForUpdate();
          console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
        } catch (err) {
          console.error('Failed to check for updates:', err);
        }
      });
    }


    updates.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.promptUser();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
        default:
          break;
      }
    });
    this.$updatedVersion.subscribe((): void => {
      console.log('hay versiones disponibles');
      this.onShowReloadDialog();
    });
  }

  private static importDialogReloadModule(): void {
    import('./dialog-reload-app/dialog-reload-app.module').then((m) => m.DialogReloadAppModule);
  }

  private promptUser(): void {
    this.$updatedVersion.next(true);
  }

  private onShowReloadDialog(): void {
    ServiceWorkerUpdateService.importDialogReloadModule();
    const config: MatDialogConfig = {
      disableClose: true,
      hasBackdrop: true,
      width: '30rem',
      maxWidth: '100%',
      maxHeight: '100%',
      data: {
        isEditing: false,
        selectedService: null
      }
    };

    const dialogRef: MatDialogRef<DialogReloadAppComponent>
      = this.dialog.open(DialogReloadAppComponent, config);

    dialogRef.afterClosed().subscribe(() => {
      document.location.reload();
      localStorage.clear();
      sessionStorage.clear();
      Cookies.remove('account');

      // this.usersService.logout()
      //   .subscribe({
      //     next: () => {
      //       this.loggedInUserService.logout();
      //       this.router.navigate(['']).then();
      //     },
      //     error: () => {
      //       this.loggedInUserService.logout();
      //       this.router.navigate(['']).then();
      //     }
        // });
    });
  }
}
